.breakerWrapper {
  /* 
  We need to set the background as a linear gradient to account for a Safari/Firefox bug where we see the top background color appear sporadically 
  underneath the bottom background color when the user resizes the browser window. The gradient covers over this behaviour.
  */
  background: linear-gradient(var(--top-color) 90%, var(--bottom-color) 10%);
  width: 100%;
  /* In the below, we are calculating the viewport percetage and then offsetting it to prevent the vw from starting at 0px. */
  height: clamp(70px, 0.862vw + 70px, 75px);

  @media (--ds-breakpoint-sm) {
    height: clamp(70px, 2.419vw + 55.97px, 85px);
  }

  @media (--ds-breakpoint-lg) {
    height: 85px;
  }
}

.bannerImageMobile,
.bannerImageTablet,
.bannerImageDesktop {
  background-color: var(--bottom-color);
  /* 
  The mask width is set at 102% here to account for the same bug referred to above. The slight overflow prevents the top background color popping
  through at the sides of the SVG mask when the user resizes the browser window.
  */
  mask-size: 102%, cover;
  mask-repeat: no-repeat;
  mask-position: bottom;
}

.bannerImageMobile {
  display: block;
  height: clamp(70px, 0.862vw + 70px, 75px);

  @media (--ds-breakpoint-sm) {
    display: none;
  }
}

.bannerImageTablet {
  display: none;

  @media (--ds-breakpoint-sm) {
    display: block;
    height: clamp(70px, 2.419vw + 55.97px, 85px);
  }

  @media (--ds-breakpoint-lg) {
    display: none;
  }
}

.bannerImageDesktop {
  display: none;

  @media (--ds-breakpoint-lg) {
    display: block;
    height: 85px;
  }
}
